<template>
  <el-container style="height: calc(100vh - 65px)">
    <el-aside>
      <div class="components-nav">
        <span>组件库</span>
      </div>
      <div>
        <div class="components" v-for="(group, i) in baseComponents" :key="i">
          <p>{{ group.name }}</p>
          <ul>
            <draggable
              class="drag"
              :list="group.components"
              :options="{ sort: false }"
              :group="{ name: 'form', pull: 'clone', put: false }"
              @start="isStart = true"
              @end="handleDragEnd"
              :clone="clone"
              filter=".undraggable"
              :move="handleMove"
            >
              <li
                v-for="(cp, id) in group.components"
                :key="id"
                :class="{ undraggable: false }"
              >
                <i :class="cp.icon"></i>
                <span>{{ cp.title }}</span>
              </li>
            </draggable>
          </ul>
        </div>
      </div>
    </el-aside>

    <el-main class="layout-main">
      <i
        :class="`el-icon-caret-${isShow ? 'right' : 'left'}`"
        @click="isShow = !isShow"
      ></i>

      <div class="work-form">
        <div class="tip" v-show="forms.length === 0 && !isStart">
          👈 请在左侧选择控件并拖至此处
        </div>
        <draggable
          class="drag-from"
          :list="forms"
          group="form"
          :move="handleMove"
          :options="{
            animation: 300,
            chosenClass: 'choose',
            sort: true,
          }"
          @start="
            drag = true;
            selectFormItem = null;
          "
          @end="drag = false"
        >
          <div
            v-for="(cp, id) in forms"
            :key="id"
            class="form-item"
            @click="selectItem(cp)"
            :style="getSelectedClass(cp)"
          >
            <div class="form-header">
              <p>
                {{ cp.title }}
              </p>
              <div class="option">
                <i class="el-icon-close" @click="del(id)"></i>
              </div>
              <form-design-render :config="cp" />
            </div>
          </div>
        </draggable>
      </div>
    </el-main>

    <el-aside class="layout-param" v-show="isShow">
      <div v-if="!selectFormItem || forms.length === 0" class="tip">
        😀 选中控件后在这里进行编辑
      </div>
      <el-tabs v-else v-model="activeName" style="padding-left: 10px">
        <el-tab-pane label="控件属性" name="first">
          <div style="text-align: left; padding: 10px">
            <form-component-config />
          </div>
        </el-tab-pane>
        <el-tab-pane label="表单属性" name="second">
          <form-base-setting></form-base-setting>
        </el-tab-pane>
      </el-tabs>
    </el-aside>
  </el-container>
</template>

<script>
import draggable from "vuedraggable";
import FormDesignRender from "@/views/admin/layout/form/FormDesignRender";
import FormComponentConfig from "@/views/common/form/FormComponentConfig";
import { baseComponents } from "@/views/common/form/ComponentsConfigExport";
import FormBaseSetting from "./FormBaseSetting.vue";

export default {
  name: "FormDesign",
  components: {
    draggable,
    FormComponentConfig,
    FormDesignRender,
    FormBaseSetting,
  },
  data() {
    return {
      formData: {},
      isStart: false,
      showMobile: false,
      baseComponents,
      select: null,
      drag: false,
      activeName: "first",
      isdisabled: 0,
      isShow: true,
      isContainer: 0,
    };
  },
  computed: {
    forms() {
      return this.$store.state.design.formItems;
    },
    selectFormItem: {
      get() {
        return this.$store.state.selectFormItem;
      },
      set(val) {
        this.$store.state.selectFormItem = val;
      },
    },
  },
  methods: {
    handleDragEnd(e) {
      this.isStart = false;
      if (e.to._prevClass === "l-drag-from") e.to.scrollLeft = e.to.scrollWidth;
    },
    handleMove(val) {
      let disabled = [
        "SerialNumber",
        "CreatedBy",
        "OwnerId",
        "CreatedDept",
        "Created",
        "Updated",
      ];

      let container = ["TableList", "SpanLayout"];
      let arr = [];
      // 设置不允许系统控件设计两次
      if (!val.dragged._prevClass) {
        this.forms.forEach((item) => {
          if (disabled.includes(item.name)) {
            arr.push(item.name);
          }
          if (container.includes(item.name)) {
            item.items.forEach((items) => {
              if (disabled.includes(items.name)) {
                arr.push(items.name);
              }
            });
          }
        });
      }
      if (arr.includes(val.draggedContext.element.name)) {
        this.isdisabled++;
        if (this.isdisabled === 1) {
          this.$message.info(`系统控件${val.draggedContext.element.name}已有`);
        }
        return false;
      } else {
        this.isdisabled = 0;
      }

      // 设置容器组件不允许嵌套容器组件
      if (
        disabled.includes(val.draggedContext.element.name) ||
        container.includes(val.draggedContext.element.name)
      ) {
        if (
          container.includes(val.draggedContext.element.name) &&
          val.to._prevClass === "l-drag-from"
        ) {
          this.isContainer++;
          if (this.isContainer === 1) {
            this.$message.info(
              `布局控件不可以嵌套${val.draggedContext.element.name}控件`
            );
          }
          return false;
        } else if (
          val.to.__vue__.$options.parent.$options._componentTag === "TableList"
        ) {
          this.isContainer++;
          if (this.isContainer === 1) {
            this.$message.info(
              `子表不可以添加${val.draggedContext.element.name}系统控件`
            );
          }
          return false;
        } else {
          this.isContainer = 0;
        }
        return true;
      }
      return true;
    },

    del(index) {
      this.$confirm(
        "删除组件将会连带删除包含该组件的条件以及相关设置，是否继续?",
        "提示",
        {
          confirmButtonText: "确 定",
          cancelButtonText: "取 消",
          type: "warning",
        }
      ).then(() => {
        this.forms.splice(index, 1);
      });
    },

    clone(obj) {
      // obj.id = this.getId();
      return JSON.parse(JSON.stringify(obj));
    },

    selectItem(cp) {
      console.log(cp);
      this.selectFormItem = cp;
      this.activeName = 'first'
    },
    getSelectedClass(cp) {
      return this.selectFormItem && this.selectFormItem.id === cp.id
        ? "border-left: 4px solid #409eff"
        : "";
    },
  },
};
</script>

<style lang="less" scoped>
.choose {
  border: 1px dashed @theme-primary !important;
}

.components-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 12px 0;
  height: 28px;
  box-shadow: 0 2px 4px 0 rgba(17, 31, 44, 0.04);
  border: 1px solid #ecedef;
  border-radius: 16px;
  color: rgba(17, 31, 44, 0.72);
  font-size: 14px;
}

.components {
  overflow-x: hidden;
  overflow-y: scroll;
  font-size: 12px;
  width: 100%;
  color: rgba(17, 31, 44, 0.85);
  & > p {
    padding: 0 20px;
  }
  .drag {
    margin-left: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    li {
      text-align: center;
      display: flex;
      align-items: center;
      width: 124px;
      height: 38px;
      margin-bottom: 12px;
      border: 1px solid transparent;
      border-radius: 8px;
      cursor: grab;
      background-color: #fff;

      &:hover {
        border: 1px solid @theme-primary;
        color: @theme-primary;
      }

      i {
        margin: 0 12px;
      }
    }

    li:nth-child(odd) {
      margin-right: 8px;
    }
  }
}

/deep/ .el-main {
  padding: 0;
}

/deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 30px;
}
.layout-main {
  background-color: #feffff;
  position: relative;
  .el-icon-caret-left,
  .el-icon-caret-right {
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 20;
    cursor: pointer;
    width: 20px;
    height: 40px;
    box-shadow: 0px 1px 10px 2px rgba(136, 136, 136, 0.3);
    line-height: 40px;
    text-align: center;
    border-radius: 4px 0 0 4px;
  }

  .work-form {
    margin: 0 auto;
    height: 100%;
    overflow-y: auto;
    background: rgb(245, 246, 246);
    border-left: 1px solid rgb(235, 236, 238);
    border-right: 1px solid rgb(235, 236, 238);
    .drag-from {
      height: 100%;
    }

    .form-item {
      cursor: grab;
      background: #ffffff;
      padding: 10px;
      border: 1px solid #ebecee;
      margin: 5px 0;
    }

    .tip {
      //float: left;
      margin: 0 auto;
      width: 65%;
      max-width: 400px;
      padding: 35px 20px;
      border-radius: 10px;
      border: 1px dashed rgba(25, 31, 37, 0.12);
      margin-top: 50px;
      text-align: center;
      font-size: 14px;
      color: rgb(122, 122, 122);
      z-index: 9999;

      &:hover {
        border: 1px dashed @theme-primary;
      }
    }
  }
}

.layout-param {
  .tip {
    font-size: 14px;
    color: rgb(122, 122, 122);
    text-align: center;
    margin-top: 150px;
  }
}

.form-header {
  font-size: small;
  color: #818181;
  text-align: left;
  position: relative;
  background-color: #fff;
  p {
    position: relative;
    margin: 0 0 10px 0;

    span {
      position: absolute;
      left: -8px;
      top: 3px;
      color: rgb(217, 0, 19);
    }
  }

  .option {
    position: absolute;
    top: -10px;
    right: -10px;
    i {
      font-size: large;
      cursor: pointer;
      color: #8c8c8c;
      padding: 5px;
      &:hover {
        color: #f56c6c;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #f8f8f8;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: #e8e8e8;
}
</style>
