<template>
  <div>
    <!-- <el-form-item label="提示文字">
      <el-input size="small" v-model="value.placeholder" placeholder="请设置提示语"/>
    </el-form-item> -->
    <el-form-item label="保留小数">
      <el-input-number
        controls-position="right"
        :precision="0"
        :max="5"
        :min="0"
        size="small"
        v-model="_format"
        placeholder="小数位数"
      />
      位
    </el-form-item>
    <el-form-item label="显示千位分隔符">
      <el-switch v-model="_option"></el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "AmountInputConfig",
  components: {},
  props: {
    format: {
      type: String,
      default: "0",
    },
    option: {
      type: String,
      default: "true",
    },
  },
  computed: {
    _format: {
      get() {
        return this.format * 1;
      },
      set(val) {
        this.$emit("update:format", val + "");
      },
    },
    _option: {
      get() {
        return this.option === "true" ? true : false;
      },
      set(val) {
        // console.log(val)
        this.$emit("update:option", val === true ? "true" : "false");
      },
    },
  },
};
</script>

<style scoped>
</style>
