<template>
  <div style="padding-left: 24px">
    <div style="margin-bottom: 10px; font-size: 14px; color: #606266">
      默认值
    </div>
    <el-input
      placeholder="请输入内容"
      v-model="_defaultVal.name"
      clearable
      readonly
      style="width: 220px; margin-bottom: 10px"
      size="medium"
      v-if="_defaultVal && _defaultVal.id"
      @click.native="$refs.orgPicker.show()"
    >
    </el-input>
    <el-select
      style="width: 220px"
      v-model="selectValue"
      placeholder="请选择"
      @change="handleClick"
      clearable
    >
      <el-option
        v-for="item in ['人员选择', '当前用户']"
        :key="item"
        :label="item"
        :value="item"
      >
      </el-option>
    </el-select>

    <div>
      <div
        style="
          font-size: 14px;
          color: #304265;
          line-height: 20px;
          margin-top: 20px;
        "
      >
        人员信息填充
        <span style="font-size: 12px; color: #304265; margin-left: 8px"
          >选择人员后，将</span
        >
      </div>

      <div
        v-for="rule in fillList"
        :key="rule.thatItemId"
        style="
          display: flex;
          align-items: center;
          height: 40px;
          justify-content: space-between;
        "
      >
        <div
          style="
            font-size: 14px;
            color: #304265;
            line-height: 20px;
            margin-top: 20px;
            height: 100%;
          "
        >
          {{ fillListName[rule.thatItemId - 1] }}
          <span style="font-size: 12px; color: #304265; margin-left: 6px"
            >{{
              fillListName[rule.thatItemId - 1] === "入职日期" ? "" : "的值"
            }}填充到</span
          >
        </div>
        <el-select
          v-model="rule.thisItemId"
          placeholder="请选择"
          style="width: 132px"
          size="medium"
          clearable
          @change="handleChange($event, rule)"
        >
          <el-option
            v-for="item in thisItemIdOptions"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <org-picker
      type="user"
      ref="orgPicker"
      :selected="activeVal"
      @ok="selected"
    />
  </div>
</template>

<script>
import OrgPicker from "@/components/common/OrgPicker";
import { getFormFillList, formFillSaveOrUpdate } from "@/api/process";
export default {
  name: "UserPickerConfig",
  components: { OrgPicker },
  data() {
    return {
      selectValue: "",
      activeVal: [],
      fillListName: ["部门", "性别", "工号", "职位", "入职日期"],
      fillList: [
        {
          thatItemId: "1",
          thisItemId: "",
        },
        {
          thatItemId: "2",
          thisItemId: "",
        },
        {
          thatItemId: "3",
          thisItemId: "",
        },
        {
          thatItemId: "4",
          thisItemId: "",
        },
        {
          thatItemId: "5",
          thisItemId: "",
        },
      ],
    };
  },
  props: {
    defaultVal: {
      type: String,
      default: "",
    },
    code: {
      type: String,
      default: "",
    },
    id: {
      type: Number,
    },
  },
  created() {
    // console.log(this.option);
    this.id &&
      this.$route.query.code &&
      getFormFillList({
        formId: this.$route.query.code,
        itemId: this.id,
      }).then((res) => {
        if (res.data?.fillList < 5) {
          this.fillList = [
            {
              thatItemId: "1",
              thisItemId: "",
            },
            {
              thatItemId: "2",
              thisItemId: "",
            },
            {
              thatItemId: "3",
              thisItemId: "",
            },
            {
              thatItemId: "4",
              thisItemId: "",
            },
            {
              thatItemId: "5",
              thisItemId: "",
            },
          ];
        } else {
          this.fillList = res.data?.fillList || [];
        }
      });
  },
  computed: {
    _defaultVal: {
      get() {
        if (this.defaultVal) {
          let val = JSON.parse(this.defaultVal);
          if (val) {
            if (val.id) {
              this.selectValue = "人员选择";
            }
            if (val.name === "当前用户") {
              this.selectValue = "当前用户";
            }
          }
          return val;
        } else {
          return null;
        }
      },
      set(val) {
        this.$emit("update:defaultVal", JSON.stringify(val));
      },
    },
    thisItemIdOptions() {
      let list = [];
      this.$store.state.design.formItems.forEach((item) => {
        if (item.id) {
          if (
            item.name !== "TableList" &&
            item.name !== "SerialNumber" &&
            item.name !== "CreatedBy" &&
            item.name !== "OwnerId" &&
            item.name !== "Created" &&
            item.name !== "Updated"
          ) {
            if (item.name === "SpanLayout") {
              list.push(...item.items);
            } else {
              list.push(item);
            }
          }
        }
      });
      console.log(list);
      return list;
    },
  },
  methods: {
    async handleChange(e, item) {
      console.log(e, this.fillList, item);
      if (!item.thisItemId) {
        this.$set(item, "thisItemCode", null);
        this.$set(item, "thisItemId", null);
      }
      let res = await formFillSaveOrUpdate({
        fillList: this.fillList,
        formId: this.$route.query.code,
        itemId: this.id,
      });
      console.log(res);
    },
    handleClick(val) {
      if (val === "人员选择") {
        this.$refs.orgPicker.show();
      } else if (val === "当前用户") {
        this._defaultVal = { name: "当前用户" };
      } else {
        this._defaultVal = null;
      }
    },
    selected(values) {
      this._defaultVal = values[0];
      this.activeVal = values;
    },
  },
};
</script>

<style>
</style>