<template>
  <div>
    <el-form label-position="top">
      <el-form-item label="选项设置" class="options">
        <div slot="label" class="option-item-label">
          <span>选项设置</span>
          <div class="button">
            <el-button type="text" size="mini" @click="handleAdd"
              >新增选项</el-button
            >

            <el-button type="text" size="mini" @click="handleBatchAdd"
              >批量编辑</el-button
            >
          </div>
        </div>
        <el-checkbox-group
          v-model="_defaultVal"
          v-if="code === 'MultipleSelect'"
        >
          <draggable
            :list="_option"
            group="option"
            handler=".el-icon-rank"
            :options="dragOption"
            @end="handleSort"
          >
            <div
              v-for="(op, index) in _option"
              :key="index"
              class="option-item"
              style="display: flex; align-items: center; margin-bottom: 10px"
            >
              <el-checkbox
                :label="op"
                style="display: flex; align-items: center"
              >
                <i class="el-icon-rank"></i>
                <el-input
                  v-model="_option[index]"
                  @input="handleChange($event, index)"
                  size="medium"
                  style="width: 220px"
                  placeholder="请设置选项值"
                  clearable
                >
                  <el-button
                    icon="el-icon-delete"
                    slot="append"
                    type="danger"
                    size="medium"
                    @click="handleDel(index)"
                  ></el-button>
                </el-input>
              </el-checkbox>
            </div>
          </draggable>
        </el-checkbox-group>
        <draggable
          :list="_option"
          group="option"
          handler=".el-icon-rank"
          :options="dragOption"
          @end="handleSort"
          v-else
        >
          <div
            v-for="(op, index) in _option"
            :key="index"
            class="option-item"
            style="display: flex; align-items: center; margin-bottom: 10px"
          >
            <el-radio
              v-model="_defaultVal"
              :label="op"
              style="display: flex; align-items: center"
            >
              <i class="el-icon-rank"></i>
              <el-input
                v-model="_option[index]"
                @input="handleChange($event, index)"
                size="medium"
                style="width: 220px"
                placeholder="请设置选项值"
                clearable
              >
                <el-button
                  icon="el-icon-delete"
                  slot="append"
                  type="danger"
                  size="medium"
                  @click="handleDel(index)"
                ></el-button>
              </el-input>
            </el-radio>
          </div>
        </draggable>
      </el-form-item>
    </el-form>
    <el-form-item label="选项展开">
      <el-switch v-model="_format"></el-switch>
    </el-form-item>
    <el-dialog title="批量编辑" :visible.sync="batchAddDialog" width="450px">
      <div>
        <p>每行对应一个选项</p>
        <el-input
          type="textarea"
          rows="6"
          v-model="batchList"
          placeholder=""
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchAddDialog = false">取 消</el-button>
        <el-button type="primary" @click="setBatchList">生成选项</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "SelectInputConfig",
  components: { draggable },
  props: {
    option: {
      type: String,
      default: "",
    },
    format: {
      type: String,
      default: "dropDown",
    },
    defaultVal: {
      type: String,
      default: "",
    },
    code: {
      type: String,
      default: "",
    },
  },
  computed: {
    _format: {
      get() {
        return this.format === "dropDown" ? false : true;
      },
      set(val) {
        this.$emit("update:format", val ? "expanding" : "dropDown");
      },
    },
    _defaultVal: {
      get() {
        if (this.code === "MultipleSelect")
          return this.defaultVal ? this.defaultVal.split(",") : [];
        else return this.defaultVal;
      },
      set(val) {
        if (this.code === "MultipleSelect")
          this.$emit("update:defaultVal", val.join(","));
        else this.$emit("update:defaultVal", val);
      },
    },
    _option: {
      get() {
        return this.option.split(",");
      },
      set(val) {
        this.$emit("update:option", val.join(","));
      },
    },
  },

  data() {
    return {
      dragOption: {
        animation: 300,
        sort: true,
      },
      batchAddDialog: false,
      batchList: "",
    };
  },
  created() {
    console.log(this.code);
  },
  methods: {
    handleSort() {
      this._option = [...this._option];
    },
    setBatchList() {
      this._option = this.batchList.split("\n");
      this.batchAddDialog = false;
    },
    handleChange(e, index) {
      let arr = this._option;
      arr.splice(index, e);
      this._option = [...arr];
    },
    handleAdd() {
      let arr = this._option;
      arr.push("新选项");
      this._option = [...arr];
    },
    handleBatchAdd() {
      this.batchList = this._option.join("\n");
      this.batchAddDialog = true;
    },
    handleDel(index) {
      let arr = this._option;
      arr.splice(index, 1);
      this._option = [...arr];
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .options {
  .el-form-item__label {
    display: block;
    width: 100%;
    text-align: left;
  }
  .el-icon-rank {
    padding-right: 5px;
    cursor: move;
  }
  .option-item {
    .el-input {
      width: 250px;
      float: right;
    }
  }
}

.option-item-label {
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  .button {
    float: right;
  }
}
/deep/ .el-radio__label {
  display: flex;
  align-items: center;
}
/deep/ .el-checkbox__label {
  display: flex !important;
  align-items: center;
}

/*/deep/ .el-form-item {
  margin-bottom: 10px;

  .el-form-item__label {
    padding: 0;
  }
  .options{
    .el-icon-rank{
      cursor: move;
    }
  }
}*/
</style>
