<template>
  <div class="base-setup" @click="showIconSelect = false">
    <el-form
      ref="baseSetting"
      :model="setup"
      label-position="top"
      label-width="10px"
    >
      <el-col :span="12">
        <el-form-item label="移动表单图标">
          <el-upload
            class="avatar-uploader"
            ref="uploadPc"
            :action="url"
            :on-success="handleAvatarSuccess"
            :show-file-list="false"
            :headers="{
              Authorization: `Bearer ${$store.getters.token}`,
            }"
          >
            <img v-if="setup.logo" :src="setup.logo" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="PC表单图标">
          <el-upload
            class="avatar-uploader"
            ref="uploadPc"
            :action="url"
            :on-success="handleAvatarSuccessPc"
            :show-file-list="false"
            :headers="{
              Authorization: `Bearer ${$store.getters.token}`,
            }"
          >
            <img v-if="setup.pcLogo" :src="setup.pcLogo" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload> </el-form-item
      ></el-col>
      <el-form-item
        label="表单名称"
        :rules="getRule('请输入表单名称')"
        prop="formName"
      >
        <el-input v-model="setup.formName" size="medium"></el-input>
      </el-form-item>
      <el-form-item label="所在分组" class="group">
        <!-- <el-input v-model="setup.groupName" ></el-input> -->
        <el-select
          v-model="setup.groupId"
          placeholder="请选择"
          style="width: 220px"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="item in groupList"
            :key="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="表单说明">
        <el-input
          placeholder="请输入表单说明"
          v-model="setup.remark"
          type="textarea"
          show-word-limit
          :autosize="{ minRows: 2, maxRows: 5 }"
          maxlength="500"
        ></el-input>
      </el-form-item>
      <el-form-item label="数据标题">
        <el-select
          v-model="setup.dataName"
          placeholder="请选择"
          multiple
          style="width: 220px"
        >
          <el-option
            :label="item.title"
            :value="item.code"
            v-for="(item, index) in dataNameList"
            :key="item.code + index"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getAllFormGroups } from "@/api/design";
export default {
  name: "FormBaseSetting",
  data() {
    return {
      showIconSelect: false,
      rules: {
        formName: [{}],
      },
      url: "",
      groupList: [],
    };
  },
  created() {
    this.url = `${process.env.VUE_APP_BASE_URL}/cloud-admin/file/upload?type=0`;
    let menuBar = JSON.parse(localStorage.getItem("menuBar"));
    getAllFormGroups((menuBar && menuBar.id) || 0).then((res) => {
      // console.log(res, "res");
      this.groupList = res.data;
    });
  },
  computed: {
    setup() {
      return this.$store.state.design;
    },
    dataNameList() {
      return this.$store.state.dataNameList;
    },
    groupName() {
      return this.$store.state.routerList.find(
        (item) => item.id === this.setup.groupId
      ).name;
    },
  },

  methods: {
    handleAvatarSuccessPc(res) {
      if (res && res.code === 200) {
        this.setup.pcLogo = res.data;
      }
    },
    handleAvatarSuccess(res) {
      if (res && res.code === 200) {
        this.setup.logo = res.data;
      }
    },
    getRule(msg) {
      return [{ required: true, message: msg, trigger: "blur" }];
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-select-dropdown {
  display: none;
}

.icon-select {
  display: flex;
  flex-wrap: wrap;
  i {
    cursor: pointer;
    font-size: large;
    padding: 10px;
    max-width: 38px !important;
    &:hover {
      box-shadow: 0 0 10px 2px #c2c2c2;
    }
  }
}

/deep/ .select-u {
  width: 100%;
}

.base-setup {
  overflow: auto;
  margin: 0 auto;
  width: 220px;
  height: calc(100vh - 105px);
  // background: #ffffff;
  margin-top: 10px;
  padding: 15px 20px;

  /deep/ .el-form-item__label {
    padding: 0;
    font-weight: bold;
  }

  /deep/ .el-form-item {
    margin-bottom: 5px;
  }
}

/deep/ .group {
  .el-select {
    width: calc(100% - 100px);
  }

  .el-button {
    margin-left: 10px;
    width: 86px;
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #f8f8f8;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: #e8e8e8;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}
.avatar {
  width: 78px;
  height: 78px;
  display: block;
}
</style>
