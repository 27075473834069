let TextInput = () => import('./components/TextInput.vue')
let AmountInput = () => import('./components/AmountInput.vue')
let TextareaInput = () => import('./components/TextareaInput.vue')
let SelectInput = () => import('./components/SelectInput.vue')
let MultipleSelect = () => import('./components/MultipleSelect.vue')
let Relevance = () => import('./components/Relevance.vue')
let MultipleRelevance = () => import('./components/Relevance.vue')
let DateTime = () => import('./components/DateTime.vue')
let City = () => import('./components/City.vue')
let Signature = () => import('./components/Signature.vue')

let Description = () => import('./components/Description.vue')
let ImageUpload = () => import('./components/ImageUpload.vue')
let FileUpload = () => import('./components/FileUpload.vue')
let DeptPicker = () => import('./components/DeptPicker.vue')
let MultipleDeptPicker = () => import('./components/DeptPicker.vue')
let UserPicker = () => import('./components/UserPicker.vue')
let MultipleUserPicker = () => import('./components/UserPicker.vue')

let SpanLayout = () => import('./components/SpanLayout.vue')
let TableList = () => import('./components/TableList.vue')

let SerialNumber = () => import('./components/SerialNumber.vue')
let CreatedBy = () => import('./components/UserPicker.vue')
let OwnerId = () => import('./components/UserPicker.vue')
let Created = () => import('./components/DateTime.vue')
let Updated = () => import('./components/DateTime.vue')
let CreatedDept = () => import('./components/DeptPicker.vue')

export default {
  //基础组件
  TextInput, AmountInput, TextareaInput, SelectInput, MultipleSelect,
  DateTime, UserPicker, MultipleUserPicker, DeptPicker,
  MultipleDeptPicker, Relevance, MultipleRelevance, City, Signature,
  //高级组件
  Description, FileUpload, ImageUpload, Location,
  SpanLayout, TableList,
  // 系统控件
  SerialNumber, CreatedBy, OwnerId, Created, Updated, CreatedDept
}
