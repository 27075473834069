<template>
  <div style="padding-left: 22px; font-size: 14px; color: #606266">
    <el-dialog
      title="设置格式"
      :visible.sync="setRulePage"
      :before-close="handleClose"
      width="500px"
    >
      <div style="height: 260px; padding: 0 10px; overflow: auto">
        <draggable
          :list="defaultRules"
          group="option"
          handler=".el-icon-rank"
          :options="dragOption"
        >
          <div
            style="
              border: 1px solid #dcdfe6;
              height: 30px;
              border-radius: 4px;
              line-height: 30px;
              padding: 0 8px;
              width: 354px;
              margin-bottom: 10px;
              margin-left: 23px;
              text-align: center;
            "
          >
            {{ rules }}
          </div>
          <div
            v-for="(op, index) in defaultRules"
            :key="index"
            class="option-item"
            style="display: flex; align-items: center; margin-bottom: 10px"
          >
            <i
              class="el-icon-rank"
              style="margin-right: 10px; cursor: pointer"
            ></i>
            <div
              style="
                border: 1px solid #dcdfe6;
                height: 30px;
                border-right: unset;
                border-radius: 4px 0 0 4px;
                line-height: 30px;
                margin-right: -2px;
                padding: 0 8px;
              "
            >
              {{ title[op.type] }}
            </div>

            <el-input-number
              v-if="op.type === 'number'"
              v-model="op.autoIncrLength"
              size="small"
              clearable
              style="width: 300px"
              controls-position="right"
              :max="8"
              :min="1"
            ></el-input-number>
            <el-select
              v-if="op.type === 'create_date'"
              v-model="op.rule"
              style="width: 300px"
              size="small"
            >
              <el-option label="年" value="yyyy" />
              <el-option label="年月" value="yyyyMM" />
              <el-option label="年月日" value="yyyyMMdd" />
            </el-select>

            <el-select
              v-if="op.type === 'field'"
              v-model="op.rule"
              style="width: 300px"
              size="small"
            >
              <el-option
                v-for="item in thisItemIdOptions"
                :key="item.id"
                :label="item.title"
                :value="item.code"
              >
              </el-option>
            </el-select>

            <el-input
              v-if="op.type === 'text'"
              v-model="op.rule"
              size="small"
              clearable
              style="width: 300px"
              placeholder="请输入1~4个数字中英文或特殊字符"
              controls-position="right"
              maxlength="4"
            ></el-input>
            <i
              style="margin-left: 10px; cursor: pointer"
              class="el-icon-delete"
              v-if="op.type !== 'number'"
              @click="delRules(index)"
            ></i>
          </div>
        </draggable>
        <el-dropdown
          @command="handleCommand"
          size="small"
          style="margin-left: 24px"
          trigger="click"
        >
          <el-button size="small">
            新增<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              command="create_date"
              :disabled="!!_option.find((item) => item.type === 'create_date')"
              >提交日期</el-dropdown-item
            >
            <el-dropdown-item command="text">固定字符</el-dropdown-item>
            <el-dropdown-item command="field">表单控件</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <span slot="footer">
        <el-button size="small" @click="handleClose">取消</el-button>
        <el-button type="primary" size="small" @click="submit">确定</el-button>
      </span>
    </el-dialog>

    <div style="display: flex; justify-content: space-between">
      <span style="font-weight: 600; color: #304265">流水号规则</span>
      <span
        style="
          color: #107fff !important;
          background-color: #fff !important;
          cursor: pointer;
        "
        @click="setRule"
        >设置</span
      >
    </div>
    <div
      v-for="(op, index) in _option"
      :key="index"
      class="option-item"
      style="display: flex; align-items: center; margin-top: 10px"
    >
      {{ title[op.type] }}：{{
        op.type === "create_date"
          ? year[op[value[op.type]]]
          : op[value[op.type]]
      }}
    </div>
    <div style="margin: 10px 0; font-weight: 600; color: #304265">
      自增位当前计数
    </div>

    <div
      style="
        padding: 6px 14px;
        border: 1px solid #e4e7ed;
        background-color: #f5f7fa;
        border-radius: 4px;
      "
      v-if="!resetCurrentCount"
    >
      {{ currentCount.currentCount }}
      <span
        style="float: right; color: #107fff !important; cursor: pointer"
        @click="resetCurrentCount = true"
        >重置</span
      >
    </div>
    <el-input v-model="currentCount.currentCount" v-else size="small">
      <el-button slot="append" @click="resetCurrentCount = false"
        >完成</el-button
      >
    </el-input>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "SerialNumberConfig",
  components: { draggable },
  props: {
    formNumList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    rules() {
      console.log(this.defaultRules);
      let str = "";
      this.defaultRules.forEach((item) => {
        if (item.type === "text") {
          str += item.rule;
        } else if (item.type === "create_date") {
          switch (item.rule) {
            case "yyyy":
              str += new Date().getFullYear().toString();
              break;
            case "yyyyMM":
              str += `${new Date().getFullYear().toString()}${(
                new Date().getMonth() + 1
              )
                .toString()
                .padStart(2, "0")}`;
              break;
            case "yyyyMMdd":
              str += `${new Date().getFullYear()}${(new Date().getMonth() + 1)
                .toString()
                .padStart(2, "0")}${new Date()
                .getDate()
                .toString()
                .padStart(2, "0")}`;
              break;
          }
        } else if (item.type === "number") {
          str += (item.currentCount + "").padStart(item.autoIncrLength, "0");
        }
      });
      return str;
    },
    currentCount() {
      return this._option.find((item) => item.type === "number");
    },
    _option: {
      get() {
        return this.formNumList;
      },
      set(val) {
        this.$emit("update:formNumList", val);
      },
    },
    thisItemIdOptions() {
      let list = [];
      let arr = ["TextInput", "TextareaInput", "AmountInput", "SpanLayout"];
      this.$store.state.design.formItems.forEach((item) => {
        if (arr.includes(item.name) && item.id) {
          if (item.name === "SpanLayout") {
            item.items.forEach((e) => {
              if (arr.includes(e.name)) {
                list.push(e);
              }
            });
          } else {
            list.push(item);
          }
        }
      });
      return list;
    },
  },
  created() {
    // console.log(this.currentCount);
  },
  data() {
    return {
      dragOption: {
        animation: 300,
        sort: true,
      },
      setRulePage: false,
      defaultRules: [{ type: "number", autoIncrLength: 4 }],
      title: {
        number: "自增数字",
        text: "固定字符",
        field: "表单控件",
        create_date: "提交日期",
      },
      value: {
        number: "autoIncrLength",
        text: "rule",
        field: "rule",
        create_date: "rule",
      },
      year: {
        yyyy: "年",
        yyyyMM: "年月",
        yyyyMMdd: "年月日",
      },
      resetCurrentCount: false,
    };
  },

  methods: {
    submit() {
      this._option = [...this.defaultRules];
      this.handleClose();
    },
    delRules(i) {
      this.defaultRules.splice(i, 1);
    },
    handleCommand(type) {
      console.log(type);
      this.defaultRules.push({
        type,
      });
    },
    handleClose() {
      this.defaultRules = [];
      this.setRulePage = false;
    },
    setRule() {
      this.defaultRules = JSON.parse(JSON.stringify(this._option));
      this.setRulePage = true;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .option-item .el-input__inner {
  border-radius: 0 4px 4px 0;
}
</style>
