<template>
  <div>
    <el-form label-position="top">
      <el-form-item label="选项设置" class="options">
        <el-cascader
          :options="routerList"
          :show-all-levels="false"
          v-model="_option"
          :props="{
            value: 'id',
            label: 'formName',
            children: 'items',
            emitPath: false,
          }"
          clearable
          style="width: 100%"
        ></el-cascader>
      </el-form-item>
      <el-form-item
        label="数据填充规则"
        class="rules"
        v-if="item.name === 'Relevance' && item.options"
      >
        <el-input
          v-model="rulesCount"
          suffix-icon="el-icon-plus"
          class="rules"
          readonly
          @click.native="handleRules"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-drawer
      title="数据填充规则"
      :visible.sync="drawer"
      direction="rtl"
      :before-close="handleClose"
      size="700px"
    >
      <div
        style="
          padding: 24px;
          background: #f5f7fa;
          margin: 24px;
          margin-bottom: 80px;
        "
      >
        <div
          v-for="(rule, index) in rules"
          :key="index"
          style="margin-bottom: 20px"
        >
          <el-select v-model="rule.thatItemId" placeholder="关联表单主表字段">
            <el-option
              v-for="item in thatItemIdOptions"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <span style="margin: 0 20px">的值填充到</span>
          <el-select v-model="rule.thisItemId" placeholder="当前表单字段">
            <el-option
              v-for="item in thisItemIdOptions"
              :key="item.id"
              :label="item.title"
              :value="item.id"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
          <i
            class="el-icon-delete"
            style="margin-left: 20px; cursor: pointer"
            @click="delRules(index)"
          ></i>
        </div>
        <div
          style="cursor: pointer; padding-bottom: 20px; width: 100px"
          @click="handleAdd"
        >
          <i class="el-icon-plus"></i> 添加规则
        </div>
      </div>
      <div class="drawer__footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleAddRules" :loading="loading">{{
          loading ? "提交中 ..." : "确 定"
        }}</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getDrawerPageOption } from "@/api/drawerPage";
import { formFillSaveOrUpdate, getFormFillList } from "@/api/process";
export default {
  name: "RelevanceConfig",
  props: {
    option: {
      type: String,
      default: "",
    },
    id: {
      type: Number,
    },
  },
  data() {
    return {
      loading: false,
      drawer: false,
      rules: [],
      thatItemIdOptions: [],
    };
  },
  created() {
    // console.log(this.option);
    this.id &&
      this.$route.query.code &&
      getFormFillList({
        formId: this.$route.query.code,
        itemId: this.id,
      }).then((res) => {
        this.rules = res.data?.fillList || [];
      });
  },
  watch: {
    rules: {
      deep: true,
      handler() {
        this.thisItemIdOptions.forEach((item) => {
          if (this.rules.find((elm) => elm.thisItemId === item.id)) {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
        });
      },
    },
  },
  computed: {
    _option: {
      get() {
        return this.option;
      },
      set(val) {
        this.$emit("update:option", val);
      },
    },
    rulesCount() {
      let num = this.rules.length || "";
      if (num) {
        return `已填充${num}项`;
      } else {
        return "";
      }
    },
    routerList() {
      let arr = this.$store.state.routerList;
      let res = [];
      arr.forEach((item) => {
        item.formName = item.name;
        if (item.items) {
          item.items = item.items.filter(
            (i) => i.id !== this.$route.query.code
          );
          res.push(item);
        }
      });
      return res;
    },
    thisItemIdOptions() {
      let list = [];
      this.$store.state.design.formItems.forEach((item) => {
        if (item.id) {
          if (
            item.name !== "TableList" &&
            item.name !== "SerialNumber" &&
            item.name !== "CreatedBy" &&
            item.name !== "OwnerId" &&
            item.name !== "Created" &&
            item.name !== "Updated" &&
            item.name !== "CreatedDept" &&
            item.id !== this.id
          ) {
            if (item.name === "SpanLayout") {
              list.push(...item.items);
            } else {
              list.push(item);
            }
          }
        }
      });
      return list;
    },
    item() {
      return this.$store.state.selectFormItem;
    },
  },
  methods: {
    async handleAddRules() {
      let flag = this.rules.find(
        (item) => !item.thatItemId || !item.thisItemId
      );
      if (flag) return this.$message.info("数据填充规则不能为空");
      // console.log();
      let res = await formFillSaveOrUpdate({
        fillList: this.rules,
        formId: this.$route.query.code,
        itemId: this.id,
      });
      if (res.code === 200) {
        this.$message.success("编辑成功");
        this.drawer = false;
      }
    },
    handleAdd() {
      this.rules.push({
        thatItemId: "",
        thisItemId: "",
      });
    },
    delRules(index) {
      this.rules = this.rules.filter((item, i) => i !== index);
    },
    async handleRules() {
      console.log(this.item)
      getDrawerPageOption(this.item.options).then((res) => {
        this.thatItemIdOptions = res.data.filter(
          (item) => item.name !== "TableList"
        );
      });
      this.drawer = true;
    },
    handleClose() {
      this.drawer = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .options {
  .el-form-item__label {
    display: block;
    width: 100%;
    text-align: left;
  }
  .el-icon-rank {
    padding-right: 5px;
    cursor: move;
  }
  .option-item {
    .el-input {
      width: 250px;
      float: right;
    }
  }
}

.option-item-label {
  height: 30px;
  line-height: 30px;
  button {
    float: right;
  }
}

/*/deep/ .el-form-item {
  margin-bottom: 10px;

  .el-form-item__label {
    padding: 0;
  }
  .options{
    .el-icon-rank{
      cursor: move;
    }
  }
}*/

.drawer__footer {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
</style>
