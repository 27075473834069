export const ValueType = {
  string: 'String',
  object: 'Object',
  array: 'Array',
  number: 'Number',
  date: 'Date',
  user: 'User',
  dept: 'Dept',
  dateRange: 'DateRange'
}

export const baseComponents = [
  {
    name: '布局',
    components: [
      {
        title: '分栏布局',
        name: 'SpanLayout',
        icon: 'el-icon-c-scale-to-original',
        items: []
      }
    ]
  }, {
    name: '基础组件',
    components: [
      {
        title: '单行文本输入',
        name: 'TextInput',
        icon: 'el-icon-edit',
        value: null,

      },
      {
        title: '多行文本输入',
        name: 'TextareaInput',
        icon: 'el-icon-more-outline',
        value: null,
      },
      {
        title: '数字输入框',
        name: 'AmountInput',
        icon: 'iconfont icon-zhufangbutiezhanghu',
        value: null,
        format: '0',
        options: 'true'
      },
      {
        title: '单选框',
        name: 'SelectInput',
        icon: 'el-icon-circle-check',
        value: null,
        options: "选项1,选项2",
        format: 'dropDown'
      },
      {
        title: '多选框',
        name: 'MultipleSelect',
        icon: 'iconfont icon-duoxuankuang',
        value: null,
        options: "选项1,选项2",
        format: 'dropDown'
      },
      {
        title: '日期时间点',
        name: 'DateTime',
        icon: 'el-icon-date',
        format: 'yyyy-MM-dd HH:mm:ss',
        value: null,
      },
      {
        title: '上传图片',
        name: 'ImageUpload',
        icon: 'el-icon-picture-outline',
        value: null,
      },
      {
        title: '上传附件',
        name: 'FileUpload',
        icon: 'el-icon-folder-opened',
        value: null,
      },
      {
        title: '人员单选',
        name: 'UserPicker',
        icon: 'el-icon-user',
        value: null,
      },
      {
        title: '人员多选',
        name: 'MultipleUserPicker',
        icon: 'el-icon-user',
        value: null,
      },
      {
        title: '部门单选',
        name: 'DeptPicker',
        icon: 'iconfont icon-map-site',
        value: null,
      },
      {
        title: '部门多选',
        name: 'MultipleDeptPicker',
        icon: 'iconfont icon-map-site',
        value: null,
      },
      {
        title: '关联单选',
        name: 'Relevance',
        icon: 'el-icon-link',
        value: null,
        options: ''
      },
      {
        title: '关联多选',
        name: 'MultipleRelevance',
        icon: 'el-icon-connection',
        value: null,
        options: ''
      },
      {
        title: '地址',
        name: 'City',
        icon: 'el-icon-location-outline',
        value: null,
        options: ''
      },
      {
        title: '说明文字',
        name: 'Description',
        icon: 'el-icon-warning-outline',
        value: null,
        options: '请设置提示内容'
      },
      {
        title: '手写签名',
        name: 'Signature',
        icon: 'el-icon-edit',
        value: null,
        options: ''
      },
    ]
  }, {
    name: '扩展组件',
    components: [
      {
        title: '明细表',
        name: 'TableList',
        icon: 'el-icon-tickets',
        value: null,
        items: [] //列设置
      }
    ]
  },
  {
    name: '系统控件',
    components: [
      {
        title: '流水号',
        name: 'SerialNumber',
        icon: 'el-icon-edit',
        value: null,
        formNumList: [
          {
            type: 'create_date',
            rule: 'yyyy',
          },
          {
            type: 'number',
            autoIncrLength: 8,
            currentCount: 1
          },

        ]
      },
      {
        title: '创建人',
        name: 'CreatedBy',
        icon: 'el-icon-user',
        value: null,
      },
      {
        title: '拥有者',
        name: 'OwnerId',
        icon: 'el-icon-s-custom',
        value: null,
      },
      {
        title: '所属部门',
        name: 'CreatedDept',
        icon: 'iconfont icon-map-site',
        value: null,
      },
      {
        title: '创建时间',
        name: 'Created',
        icon: 'el-icon-time',
        value: null,
        format: 'yyyy-MM-dd HH:mm:ss',
      },
      {
        title: '修改时间',
        name: 'Updated',
        icon: 'el-icon-time',
        value: null,
        format: 'yyyy-MM-dd HH:mm:ss',
      }
    ]
  }
]



export default {
  baseComponents
}

