<template>
  <component ref="form" :is="config.name" :format="config.format" :items="config.items" :option="config.options" :columns="config.items"/>
</template>
<script>

import components from '@/views/common/form/ComponentExport'

export default {
  name: "FormDesignRender",
  components: components,
  props:{
    config:{
      type: Object,
      default: ()=>{
        return {}
      }
    }
  },
  data() {
    return {}
  },

}
</script>

<style lang="less" scoped>

</style>
