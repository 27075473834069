<template>
  <div>
    <el-form-item label="日期格式">
      <el-select size="small" v-model="_format">
        <el-option value="yyyy-MM" label="年-月"></el-option>
        <el-option value="yyyy-MM-dd" label="年-月-日"></el-option>
        <el-option
          value="yyyy-MM-dd HH:mm:ss"
          label="年-月-日 时:分:秒"
        ></el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "DateTime",
  components: {},
  props: {
    format: {
      type: String,
      default: "yyyy-MM-dd HH:mm:ss",
    },
  },
  computed: {
    _format: {
      get() {
        return this.format;
      },
      set(val) {
        this.$emit("update:format", val);
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
</style>
