var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.form.name !== 'SpanLayout')?_c('el-form',{attrs:{"label-width":"90px"}},[(_vm.form.code)?_c('el-form-item',{attrs:{"label":"控件编码"}},[_c('el-input',{attrs:{"size":"small","readonly":""},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"控件名称"}},[_c('el-input',{attrs:{"size":"small","clearable":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('el-form-item',{attrs:{"label":"组件名称"}},[_c('el-input',{attrs:{"size":"small","disabled":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"隐藏条件"}},[_c('el-input',{attrs:{"size":"small","readonly":"","value":_vm.form.hideValue}},[_c('i',{staticClass:"el-input__icon el-icon-plus",attrs:{"slot":"suffix"},on:{"click":_vm.handleValueHidden},slot:"suffix"})])],1),(
        ![
          'TextInput',
          'CreatedBy',
          'TextareaInput',
          'ImageUpload',
          'FileUpload',
          'DeptPicker',
          'MultipleDeptPicker',
          'MultipleUserPicker',
          'TableList',
          'OwnerId',
          'CreatedDept',
          'Signature',
          'City',
        ].includes(_vm.form.name)
      )?_c(_vm.form.name,{tag:"component",attrs:{"formNumList":_vm.form.formNumList,"option":_vm.form.options,"code":_vm.form.name,"id":_vm.form.id,"format":_vm.form.format,"defaultVal":_vm.form.value},on:{"update:formNumList":function($event){return _vm.$set(_vm.form, "formNumList", $event)},"update:form-num-list":function($event){return _vm.$set(_vm.form, "formNumList", $event)},"update:option":function($event){return _vm.$set(_vm.form, "options", $event)},"update:code":function($event){return _vm.$set(_vm.form, "name", $event)},"update:format":function($event){return _vm.$set(_vm.form, "format", $event)},"update:defaultVal":function($event){return _vm.$set(_vm.form, "value", $event)},"update:default-val":function($event){return _vm.$set(_vm.form, "value", $event)}}}):_vm._e()],1):_c('el-empty',{attrs:{"description":"当前组件不支持配置"}}),_c('div',[_c('SetHide',{attrs:{"isHidden":_vm.isHidden,"rulesTitle":"当满足以下条件时此控件隐藏","type":"isHidden","dom":_vm.dom},on:{"update:isHidden":function($event){_vm.isHidden=$event},"update:is-hidden":function($event){_vm.isHidden=$event},"update:dom":function($event){_vm.dom=$event}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }