<template>
  <div>
    <el-dialog
      :title="SetHideTitle"
      width="800px"
      :visible.sync="handleClose"
      :modal="false"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <div class="fnList" v-if="showFnList">
        <el-table
          :data="fnList"
          :show-header="false"
          border
          lazy
          style="max-height: 550px !important; overflow: auto !important"
          :load="loadFnTree"
          row-key="id"
          :tree-props="{
            children: 'children',
            hasChildren: 'hasChildren',
          }"
          :row-style="{ cursor: 'pointer' }"
          @row-click="handleFnRowClick"
        >
          <el-table-column prop="name"> </el-table-column>
        </el-table>
      </div>

      <div style="height: 400px" class="hiddenCondition">
        <div class="left">
          <div
            style="margin-bottom: 10px"
            v-if="moduleList && moduleList.length > 1"
          >
            <span class="title"
              >目标表单
              <el-popover
                placement="bottom"
                width="200"
                trigger="click"
                v-model="showPopover"
              >
                <el-table
                  :data="moduleList"
                  :show-header="false"
                  border
                  lazy
                  style="
                    max-height: 420px !important;
                    overflow: auto !important;
                  "
                  row-key="id"
                  :load="loadTree"
                  :tree-props="{
                    children: 'items',
                    hasChildren: 'hasChildren',
                  }"
                  :row-style="{ cursor: 'pointer' }"
                  @row-click="handleRowClick"
                >
                  <el-table-column prop="name"> </el-table-column>
                </el-table>
                <span
                  slot="reference"
                  style="
                    float: right;
                    color: #107fff;
                    font-size: 12px;
                    cursor: pointer;
                  "
                  >添加
                </span>
              </el-popover>
            </span>
            <div
              style="cursor: pointer; padding: 5px 10px"
              class="items"
              @click="handleHiddenCondition(targetRow, 'green', 'targetRow')"
            >
              {{ targetRow.formName }}
            </div>
            <div
              v-for="(item, index) in targetTable"
              :key="index"
              class="items"
              @click="handleHiddenCondition(item, 'green')"
            >
              {{ item.title }}
            </div>
          </div>
          <div>
            <span class="title" style="border-top: 1px solid #ebecee"
              >当前表单</span
            >
            <div
              v-for="(item, index) in conditionList"
              :key="index"
              class="items"
              @click="handleHiddenCondition(item, 'blue')"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
        <div class="right">
          <div
            style="
              border-bottom: 1px solid #ebecee;
              padding: 5px;
              background-color: rgb(232, 244, 254);
            "
          >
            {{ rulesTitle }}
          </div>
          <!-- contenteditable 开启div可编辑模式 -->
          <div
            contenteditable="true"
            style="height: 200px"
            :placeholder="placeholder"
            class="inputsx"
            tabindex="0"
            ref="input"
          ></div>
          <div style="padding: 10px 0 0 10px" class="rulesTitle">
            <ul>
              <li
                style="margin-bottom: 5px"
                v-for="(item, index) in rules"
                :key="index"
              >
                <span style="font-weight: 700">·</span>
                {{ item }}
              </li>
            </ul>
            <div class="function" @click="handleFnShow">插入函数</div>
          </div>
        </div>
      </div>
      <div style="text-align: right">
        <span style="margin-right: 50px; color: red">{{ errorMessage }}</span>
        <el-button size="small" @click="setHandleClose">取消</el-button>
        <el-button type="primary" size="small" @click="handleCalculate"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getFormGroups,
  getFormItem,
  getFnMap,
  fnValidate,
  updateRule,
} from "@/api/design";
export default {
  props: {
    isHidden: {
      type: Boolean,
      required: true,
    },
    rulesTitle: {
      type: String,
      default: "当满足以下条件时此控件隐藏",
    },
    placeholder: {
      type: String,
      default: "例：报销金额>10000",
    },
    rulesArr: {
      type: Array,
      default: () => [
        "请从左侧面板选择字段或选项",
        "支持英文模式下运算符(+、-、*、/、>、&lt;、==、!=、&lt;=、>=)",
        "报销金额控件输入的值大于10000时,需要隐藏当前控件,则可将隐藏条件设置为:报销金额>10000",
      ],
    },
    moduleList: {
      type: Array,
      default: () => [],
    },
    SetHideTitle: {
      type: String,
      default: "隐藏条件",
    },
    dom: {
      type: Object,
      default: () => {},
    },
    tag: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
  computed: {
    conditionList() {
      let arr = this.$store.state.design.formItems;
      let newArr = [];
      arr &&
        arr.forEach((item) => {
          if (item.code === "SpanLayout" || item.code === "TableList")
            newArr.push(...item.items);
          else newArr.push(item);
        });
      // console.log(newArr);
      return newArr;
    },
    handleClose: {
      get() {
        return this.isHidden;
      },
      set() {
        this.$emit("update:isHidden", false);
      },
    },
    formRulesList() {
      return this.$store.state.formRulesList;
    },
    form() {
      return this.$store.state.selectFormItem;
    },
  },
  data() {
    return {
      targetTable: [],
      targetRow: {},
      showPopover: false,
      showFnList: false,
      fnList: [],
      rules: "",
      errorMessage: "",
    };
  },
  watch: {
    isHidden(val) {
      if (val) {
        if (
          (this.dom && this.dom.domStr) ||
          (this.dom && this.dom.targetFormName)
        ) {
          console.log(this.dom);
          if (this.type === "isHidden") {
            this.$nextTick(() => {
              let currentDiv = this.$refs.input;
              if (this.dom && this.dom.domStr) {
                this.dom.domStr.split("/").forEach((item) => {
                  // console.log(item);
                  if (item[0] === "{") {
                    let obj = JSON.parse(item);
                    let newObj = {
                      ...obj,
                      formName: obj.text,
                      id: obj.code,
                      title: obj.text,
                    };
                    this.handleHiddenCondition(newObj, "blue");
                  } else {
                    let newSpan = document.createElement("span");
                    newSpan.innerText = item;
                    currentDiv.appendChild(newSpan);
                  }
                });
              }
            });
          } else {
            this.handleRowClick({
              formName: this.dom.targetFormName,
              id: this.dom.targetFormId,
            });
            this.$nextTick(() => {
              let currentDiv = this.$refs.input;
              if (this.dom.expression && this.dom.expression.domStr) {
                this.dom.expression.domStr.split("/").forEach((item) => {
                  // console.log(item);
                  if (item[0] === "{") {
                    let obj = JSON.parse(item);
                    let newObj = {
                      ...obj,
                      formName: obj.text,
                      id: obj.code,
                      title: obj.text,
                    };
                    if (
                      obj.code.includes(this.dom.targetFormId) &&
                      obj.code.includes(".")
                    ) {
                      newObj = {
                        formName: obj.text.split(".")[1],
                        id: obj.code.split(".")[1],
                        title: obj.text.split(".")[1],
                        code: obj.code.split(".")[1],
                      };
                      this.handleHiddenCondition(newObj, "green");
                    } else if (obj.code.includes(this.dom.targetFormId)) {
                      this.handleHiddenCondition(newObj, "green", "target");
                    } else {
                      this.handleHiddenCondition(newObj, "blue");
                    }
                  } else {
                    let newSpan = document.createElement("span");
                    newSpan.innerText = item;
                    currentDiv.appendChild(newSpan);
                  }
                });
              }
            });
          }
        }
        this.rules = this.rulesArr;
        getFnMap().then((res) => {
          this.fnList = [];
          let i = 100;
          for (const k in res.data) {
            this.fnList.push({
              name: k,
              hasChildren: true,
              children: res.data[k],
              id: i++,
            });
          }
        });
      }
    },
  },

  methods: {
    // 切换函数是否显示
    handleFnShow() {
      this.showFnList = !this.showFnList;
    },
    // 目标表单点击
    async handleRowClick(row) {
      if (row.hasChildren) return;
      console.log(row);
      this.targetRow = row;
      let res = await getFormItem(row.id);
      console.log(res);
      let list =
        [
          {
            code: "id",
            name: "ObjectId",
            title: "ObjectId",
          },
          ...res.data,
        ] || [];
      list.forEach((item) => {
        if (item.items) {
          item.hasChildren = true;
        }
      });
      this.targetTable = list;
      this.showPopover = false;
    },
    // 获取函数表单解构
    loadFnTree(tree, treeNode, resolve) {
      resolve(tree.children);
    },
    // 获取目标表单的树解构
    loadTree(tree, treeNode, resolve) {
      console.log(tree);
      if (tree.children) {
        let list = tree.items;
        list.forEach((item) => {
          item.name = item.formName;
        });
        resolve(list);
      } else {
        getFormGroups(tree.id).then((res) => {
          let list = [];
          res.data.forEach((item) => {
            if (item.items) {
              item.hasChildren = true;
              item.children = true;
              list.push(item);
            }
          });
          resolve(list);
        });
      }
    },
    // 关闭对话框
    setHandleClose() {
      this.$emit("update:isHidden", false);
    },
    // 点击目标及函数生成解构
    handleHiddenCondition(item, str, target) {
      // console.log(item);
      let text = "";
      if (target) {
        text = item.formName;
      } else if (str === "green") {
        text = this.targetRow.formName + "." + item.title;
      } else {
        text = item.title;
      }
      let newImg = document.createElement("img");
      if (target) {
        newImg["expression-text"] = item.formName;
        newImg["data-value"] = item.id;
      } else if (str === "green") {
        newImg["expression-text"] = this.targetRow.formName + "." + item.title;
        newImg["data-value"] = this.targetRow.id + "." + item.code;
      } else {
        newImg["expression-text"] = item.title;
        newImg["data-value"] = item.code;
      }
      // console.log(text);

      var canvas = document.createElement("canvas");
      canvas.width = this.getTextMax(text);
      canvas.height = 20;
      var ctx = canvas.getContext("2d");
      ctx.font = "italic 14px Arial";
      ctx.fillStyle = str;
      ctx.fillText(text, 5, 14);
      newImg.src = canvas.toDataURL("image/png");
      newImg.style.margin = "0 4px";
      newImg.style["vertical-align"] = "middle";
      let currentDiv = this.$refs.input;
      currentDiv.appendChild(newImg);
      this.handleFocus(currentDiv);
    },
    // 表单聚焦
    handleFocus(dom) {
      let sel = window.getSelection();
      let range = document.createRange();
      range.selectNodeContents(dom);
      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
    },
    // 函数表表单点击
    handleFnRowClick(row) {
      if (row.hasChildren) return;
      console.log(row);
      let newSpan = document.createElement("span");
      newSpan.innerText = row.showName + "(";
      let currentDiv = this.$refs.input;
      currentDiv.appendChild(newSpan);
      this.rules = [`实例：${row.example}`, `备注：${row.remark}`];
      this.handleFocus(currentDiv);
    },
    // 获取图片宽度
    getTextMax(str) {
      let num = 0;
      for (let i = 0; i < str.length; i++) {
        if (str[i] === ".") {
          num += 1;
        } else {
          num += 16;
        }
      }
      return num;
    },
    // 获取解构
    async handleCalculate() {
      console.log(this.type);
      let dom = this.$refs.input.childNodes;
      // this.setHandleClose();
      let text = "";
      let code = "";
      let domStr = "";
      let hiddenVal = "";
      dom.forEach((item) => {
        if (item.nodeName === "SPAN") {
          text += item.innerText;
          code += item.innerText;
          domStr += item.innerText;
          hiddenVal += item.innerText;
        }
        if (item.nodeName === "IMG") {
          text += `{${item["expression-text"]}}`;
          code += `{${item["data-value"]}}`;
          domStr += `/{"text":"${item["expression-text"]}","code":"${item["data-value"]}"}/`;
          hiddenVal += `val.${item["data-value"]}`;
        }
        if (item.nodeName === "#text") {
          text += item.data;
          code += item.data;
          domStr += item.data;
          hiddenVal += item.data;
        }
      });

      let obj = {
        text: text,
        code: code,
        domStr,
        hiddenVal,
      };
      if (this.type !== "isHidden") {
        let res = await fnValidate({
          expression: obj,
          formId: this.$route.query.code,
          targetFormId:
            this.dom && this.dom.targetFormId === this.targetRow.id
              ? this.dom.targetFormId
              : this.targetRow.id,
        });
        console.log(res);
        if (res.code !== 200) {
          this.errorMessage = res.message;
        } else {
          this.errorMessage = "";
        }
        // 外面原数组
        let list = JSON.parse(JSON.stringify(this.formRulesList));
        // 当前数据
        if (this.dom) {
          list.forEach((item) => {
            if (item.tag === this.tag) {
              item.formRules[this.dom.type - 1] = {
                expression: obj,
                targetFormId: this.targetRow.id,
                type: this.dom.type,
                id:
                  item.formRules[this.dom.type - 1].id !== null
                    ? item.formRules[this.dom.type - 1].id
                    : null,
              };
            }
          });
        } else {
          list.push({
            formId: this.$route.query.code,
            formRules: [
              {
                expression: obj,
                targetFormId: this.targetRow.id,
                type: 1,
              },
            ],
          });
        }
        console.log(list);
        if (res.code === 200) {
          updateRule(list).then(() => {
            this.setHandleClose();
            this.$emit("getList");
          });
        }
      } else {
        this.$emit("update:dom", obj);
        this.setHandleClose();
      }
    },
  },
};
</script>

<style  scoped lang="less">
.el-dialog {
  position: relative;
  .fnList {
    position: absolute;
    width: 200px;
    height: 566px;
    border: 1px solid #ebecee;
    right: -210px;
    top: 0;
    border-radius: 5px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 4px;
    z-index: 2;
    box-sizing: border-box;
  }
}
.hiddenCondition {
  display: flex;
  margin-bottom: 20px;
  font-size: 12px;

  .left {
    width: 160px;
    height: 100%;
    margin-right: 10px;
    border: 1px solid #ebecee;
    overflow: auto;
    .title {
      border-bottom: 1px solid #ebecee;
      display: block;
      padding: 5px;
    }
    .items {
      padding: 5px 20px;
      &:hover {
        background-color: #ebecee;
        cursor: pointer;
      }
    }
  }
  .right {
    flex: 1;
    width: 140px;
    border: 1px solid #ebecee;
    height: 100%;
  }
}
/deep/ .el-table td.el-table__cell div {
  cursor: pointer;
}
.rulesTitle {
  position: relative;
  .function {
    position: absolute;
    top: -30px;
    right: 7px;
    font-size: 12px;
    line-height: 20px;
    padding: 1px 4px;
    border: 1px solid #107fff;
    border-radius: 3px;
    cursor: pointer;
  }
}
.inputsx {
  padding: 5px;
  border-bottom: 1px solid #ebecee;
}

.inputsx:empty::before {
  content: attr(placeholder);
}
</style>